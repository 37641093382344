import store from '@/store'
import { mdiAccountCheckOutline, mdiAccountOutline, mdiAccountPlusOutline, mdiAccountRemoveOutline, mdiCogOutline, mdiDatabaseOutline, mdiDnsOutline, mdiPencilOutline } from '@mdi/js'
import { ref, watch } from '@vue/composition-api'

export default function usePassengerList() {
    const headers = [
        { text: '真实姓名', value: 'real_name' },
        { text: '手机号', value: 'mobile' },
        { text: '性别', value: 'sex' },
        { text: '备用手机', value: 'mobile_back' },
        { text: '状态', value: 'state' },
        { text: '评分', value: 'star' },
        { text: '成功订单', value: 'order_success' },
        { text: '注册时间', value: 'create_at' },
        { text: '操作', value: 'actions', align: 'center', sortable: false }
    ]
    const param = ref({
        key: '',
        options: { sortBy: ['id'], sortDesc: [true], page: 1, itemsPerPage: 10 }
    })
    const table = ref({
        list: [],
        total: 0,
        lodading: false,
        snak: { open: false, msg: '' },
        dialogBlack: {
            open: false,
            item: { real_name: "", mobile: "", star: "", order_success: "" },
            content: { reason: '' }
        }
    })
    const enums = {
        status: {
            "1": "正常",
            "2": "禁用",
            "3": "拉黑",
        },
        statusColor: {
            "1": "success",
            "2": "secondary",
            "3": "error",
        },
    }
    const toBlack = (item) => {
        table.value.dialogBlack = {
            open: true,
            item: item,
            content: { id: item.id, reason: '' }
        }
    }
    const fetchPassengerList = () => {
        table.value.loading = true
        store.dispatch('app-user-passenger-list/fetchPassengerList', param.value).then(res => {
            const { code, msg } = res.data
            if (code !== 200) {
                table.value.snak = { open: true, msg: `错误码：${code}, ${msg}` }
                return
            }
            const { list, total } = res.data.data
            table.value.list = list || []
            table.value.total = total
        }).catch(error => { table.value.snak = { open: true, msg: `网络错误` } }).finally(table.value.loading = false)
    }

    const passengerBlack = () => {
        table.value.loading = true
        const data = table.value.dialogBlack.content
        store.dispatch('app-user-passenger-list/passengerBlack', data).then(res => {
            const { code, msg } = res.data
            if (code !== 200) {
                table.value.snak = { open: true, msg: `错误码：${code}, ${msg}` }
                return
            }
            table.value.dialogBlack.open = false
            fetchPassengerList()
        }).catch(error => { table.value.snak = { open: true, msg: `网络错误` + error } }).finally(table.value.loading = false)
    }

    const unBlack = (item) => {
        table.value.loading = true
        const data = {
            id: item.id
        }
        store.dispatch('app-user-passenger-list/passengerUnBlack', data).then(res => {
            const { code, msg } = res.data
            if (code !== 200) {
                table.value.snak = { open: true, msg: `错误码：${code}, ${msg}` }
                return
            }
            table.value.dialogBlack.open = false
            fetchPassengerList()
        }).catch(error => { table.value.snak = { open: true, msg: `网络错误` + error } }).finally(table.value.loading = false)
    }

    watch([param], () => {
        fetchPassengerList()
    }, { deep: true })
    return {
        headers,
        param,
        table,
        enums,
        toBlack, unBlack,
        passengerBlack,
        fetchPassengerList
    }
}
