import axios from '@axios'

export default {
    namespaced: true, state: {}, getters: {}, mutations: {}, actions: {

        fetchPassengerList(ctx, queryParams) {
            return new Promise((resolve, reject) => {
                axios.get(`/admin-company/v1/app/user-passenger/${queryParams.options.page}/${queryParams.options.itemsPerPage}`, { params: queryParams }).then(response => resolve(response)).catch(error => reject(error))
            })
        },
        passengerBlack(ctx, data) {
            return new Promise((resolve, reject) => {
                axios.post(`/admin-company/v1/app/user-passenger/black`, data).then(response => resolve(response)).catch(error => reject(error))
            })
        },
        passengerUnBlack(ctx, data) {
            return new Promise((resolve, reject) => {
                axios.post(`/admin-company/v1/app/user-passenger/unblack`, data).then(response => resolve(response)).catch(error => reject(error))
            })
        },

        fetchBlacklistList(ctx, queryParams) {
            return new Promise((resolve, reject) => {
                axios.get(`/admin-company/v1/app/user-blacklist/${queryParams.options.page}/${queryParams.options.itemsPerPage}`, { params: queryParams }).then(response => resolve(response)).catch(error => reject(error))
            })
        },
        blacklistRemove(ctx, data) {
            console.log(data);
            return new Promise((resolve, reject) => {
                axios.delete(`/admin-company/v1/app/user-blacklist/${data.id}`).then(response => resolve(response)).catch(error => reject(error))
            })
        },

        fetchRealAuthList(ctx, queryParams) {
            return new Promise((resolve, reject) => {
                axios.get(`/admin-company/v1/app/user-passenger/real-auth/${queryParams.options.page}/${queryParams.options.itemsPerPage}`, { params: queryParams }).then(response => resolve(response)).catch(error => reject(error))
            })
        },
        fetchRealAuthAccept(ctx, item) {
            return new Promise((resolve, reject) => {
                axios.put(`/admin-company/v1/app/user-passenger/real-auth/accept/${item.id}`).then(response => resolve(response)).catch(error => reject(error))
            })
        },
        fetchRealAuthRefuse(ctx, item) {
            return new Promise((resolve, reject) => {
                axios.put(`/admin-company/v1/app/user-passenger/real-auth/refuse/${item.id}`, { reason: item.failed_reason }).then(response => resolve(response)).catch(error => reject(error))
            })
        },
    },
}
