<template>
    <div id='user-list'>
        <v-dialog v-model="table.dialogBlack.open" persistent max-width="600px">
            <v-card>
                <v-card-title>
                    <span class="headline">拉黑乘客</span>
                </v-card-title>
                <v-card-text>
                    <v-container>
                        <v-row>
                            <v-col cols="12" sm="6" md="6">
                                <v-text-field readonly v-model="table.dialogBlack.item.real_name" label="真实姓名"
                                    dense></v-text-field>
                            </v-col>
                            <v-col cols="12" sm="6" md="6">
                                <v-text-field readonly v-model="table.dialogBlack.item.mobile" label="手机号"
                                    dense></v-text-field>
                            </v-col>
                            <v-col cols="12" sm="6" md="6">
                                <v-text-field readonly v-model="table.dialogBlack.item.star" label="评分"
                                    dense></v-text-field>
                            </v-col>
                            <v-col cols="12" sm="6" md="6">
                                <v-text-field readonly v-model="table.dialogBlack.item.order_success" label="订单数"
                                    dense></v-text-field>
                            </v-col>
                            <v-col cols="12" sm="12" md="12">
                                <v-textarea v-model="table.dialogBlack.content.reason" label="拉黑原因" dense
                                    hint="请输入拉黑原因"></v-textarea>
                            </v-col>
                        </v-row>
                    </v-container>
                </v-card-text> <v-card-actions> <v-spacer></v-spacer> <v-btn color="error" outlined
                        @click="table.dialogBlack.open = false"> 取消 </v-btn> <v-btn color="success"
                        @click="passengerBlack()">
                        提交 </v-btn> </v-card-actions> </v-card>
        </v-dialog>
        <v-snackbar v-model="table.snak.open" multi-line :light="$vuetify.theme.dark" :timeout="2000">
            {{ table.snak.msg }}
            <template #action="{ attrs }">
                <v-btn color="error" text v-bind="attrs" @click="table.snak.open = false"> 关闭 </v-btn>
            </template>
        </v-snackbar>
        <v-card>
            <v-divider class='mt-4'></v-divider>
            <v-card-text class='d-flex align-center flex-wrap pb-4'>
                <v-text-field v-model='param.key' class='user-search me-3' dense hide-details outlined placeholder='搜索'
                    clearable></v-text-field>
                <v-spacer></v-spacer>
            </v-card-text>
            <!-- table -->
            <v-data-table :headers='headers' :items='table.list' :loading='table.loading' :options.sync='param.options'
                :server-items-length='table.total' show-select>
                <template #[`item.state`]='{ item }'>
                    <v-chip :color="enums.statusColor[item.state]" small>{{ enums.status[item.state] }}</v-chip>
                </template>
                <template #[`item.actions`]='{ item }'>
                    <v-btn @click="toBlack(item)" v-if="item.state == 1" color="error" small>拉黑</v-btn>
                    <v-btn @click="unBlack(item)" v-if="item.state == 3" color="info" small>取消拉黑</v-btn>
                </template>
            </v-data-table>
        </v-card>
    </div>
</template>
<script>
// eslint-disable-next-line object-curly-newline
import { mdiAccountOutline, mdiDeleteOutline, mdiDotsVertical, mdiExportVariant, mdiPencil, mdiPlus, mdiSquareEditOutline, } from '@mdi/js'
import store from '@/store'
import { onUnmounted, ref } from '@vue/composition-api'
import passengerStoreModule from '../passengerStoreModule'
import usePassengerList from './usePassengerList'

export default {
    components: {}, setup() {
        const USER_APP_STORE_MODULE_NAME = 'app-user-passenger-list'
        // Register module
        if (!store.hasModule(USER_APP_STORE_MODULE_NAME)) store.registerModule(USER_APP_STORE_MODULE_NAME, passengerStoreModule)
        // UnRegister on leave
        onUnmounted(() => {
            if (store.hasModule(USER_APP_STORE_MODULE_NAME)) store.unregisterModule(USER_APP_STORE_MODULE_NAME)
        })
        const { headers, param, table, enums, toBlack, unBlack, passengerBlack, fetchPassengerList } = usePassengerList()

        // fetchPassengerList()

        return {
            headers,
            param,
            table,
            enums,
            toBlack, unBlack,
            passengerBlack,
            fetchPassengerList,
            // icons
            icons: { mdiSquareEditOutline, mdiPencil, mdiDotsVertical, mdiDeleteOutline, mdiPlus, mdiExportVariant, mdiAccountOutline, },
        }
    },
} </script>
<style lang='scss'> @import '@core/preset/preset/apps/user.scss';
</style>
